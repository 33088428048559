.CARD{
    width: 22%;
    min-height: 300px;
    height: auto;
    background-color: rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-radius: 20px;
    gap: 10px;
    
}
.CARD:hover{
transform: translateY(-5px) scale(1.05); /* رفع وتكبير الزر عند التمرير */
box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* زيادة الظل عند التمرير */
}

.CARD img{
    width: 90%;
    height: 70%;
    margin-top: 10px;
    border-radius: 20px;
    object-fit: cover;
    /* margin-top: 20px !important; */
    margin: 10 !important;
}
.CARD h1{
    width: 100%;
    /* height: 25%; */
    font-size: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    
    margin: auto !important;
    margin-top:  0 !important;
    padding: 0;
}
.OurCur{
    width: 100%;
    min-height: 100vh;
    text-align: center;
}
.ali{
    margin: 40px 6%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 20px;
}
.ali .CARD{
    margin: 0 20px;
}
