.backg {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  margin-top: -50px;
  color: #005ca4;
}
.rieghtCon {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  max-height: 75vh;
  height: auto;
  justify-content: left;
  width: 50%;
  padding: 20px;
  color: white;
  /* width: 100%; تحديد عرض الحاوية */
  /* word-wrap: break-word; كسر الكلمة إذا كانت طويلة */
  overflow-wrap: break-word; /* توفير دعم إضافي في المتصفحات الحديثة */
  /* white-space: normal; /* يسمح بنزول النص إلى السطر التالي */
  /* word-break: break-all */
}
.head {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* margin: 10px 10px 0 10px; */
  color: #005ca4;
  gap: 23%;
  margin: 0;
}
.leftCon {
  display: flex;
  width: 22%;
  flex-direction: column;
  height: 69vh;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 10px;
  align-self: flex-start;
}
.mid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  gap: 20px;
}
.mid img {
  margin: 0 !important;
}
.head h1 {
  font-size: 40px;
  text-decoration: underline #f4942b;
}
.timeline {
  width: 90%;
  background-color: white;
  border: black;
  overflow-y: scroll;
  border-radius: 20px;
  height: 300px !important;
}
.time {
  overflow-y: scroll;
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.head h1 {
  width: 80%;
  margin: 0;
}
.order {
  width: 20%;
  padding: 5px;
  background-color: #f4942b;
  border: none;
  border-radius: 20px;
  margin: 0;
  color: white;
}
.Button {
  display: flex;
  flex-direction: row;
}
.But {
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 8px;
  margin: 0 !important;
}
.order:hover {
  background: linear-gradient(
    45deg,
    #ff6f61,
    #f4942b
  ); /* عكس التدرج اللوني عند التمرير */
  transform: translateY(-5px) scale(1.05); /* رفع وتكبير الزر عند التمرير */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar {
  width: 12px; /* عرض شريط التمرير */
  display: none;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* لون خلفية مسار التمرير */
  border-radius: 10px; /* حواف دائرية لمسار التمرير */
}

::-webkit-scrollbar-thumb {
  background: #4478a8; /* لون مقبض التمرير */
  border-radius: 10px; /* حواف دائرية لمقبض التمرير */
}

::-webkit-scrollbar-thumb:hover {
  background: #f4942b; /* لون مقبض التمرير عند التمرير */
}
