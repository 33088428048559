.firstsection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50%;
    margin-top: 49px ;
    /* display:inline !important; */
}

.person {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.person img {
    /* border: 2px solid #cf376c; */
    object-fit: cover;
    border-radius: 50%;
    height: 180px; /* تأكد من أن العرض والارتفاع متساويان */
    width: 180px;  /* تأكد من أن العرض والارتفاع متساويان */
}