@media (max-width: 600px) {
    .photo{
        display: none;
    }
    .le{
        margin: 0px !important;
        width: 100%;
    }
    .le h1{
        margin: 0 !important;
    }
    .All{
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 20px;
        overflow-x: hidden;
        width: 100%;
        margin: 0px !important;
        gap: 40px !important;
    }
    .re{
        width: 100%;
        height: auto;
    }
    .All .img{
        width: 100%;
        height: 80%;
    }
}