@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.conti {
  width: 75% !important;
  height: 450px;
  background-color: #e7e7e7;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 40px;
  gap: 40px;
  color: #0d5aa4;
  justify-content: center;
  opacity: 0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.5s forwards ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.three .conti:hover {
  transform: scale(1.05); /* تكبير الكارد عند التمرير */
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px !important;
  height: 50%;
  text-align: center;
  max-width: 250px !important;
}

.dowm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  height: 50%;
  text-align: center;
  max-width: 250px !important;
}

.up {
  height: 50%;
  width: 100%;
}

.up img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.butt {
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.buten1,
.buten2 {
  min-width: 150px; /* تعديل العرض */
  height: 50px; /* تعديل الارتفاع */
  border-radius: 25px; /* زوايا دائرية أكبر */
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: linear-gradient(45deg, #5d9cd6, #0d5aa4); /* تدرج لوني قطري */
  color: white;
  font-size: 18px; /* تكبير حجم النص */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* إضافة ظل */
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.buten2:hover {
  background: linear-gradient(45deg, #0d5aa4, #5d9cd6); /* عكس التدرج اللوني عند التمرير */
  transform: translateY(-5px) scale(1.05); /* رفع الزر للأعلى وتكبيره عند التمرير */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* زيادة الظل عند التمرير */
}

.buten2:active {
  transform: scale(0.95); /* تقليص الزر عند الضغط */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* تقليل الظل عند الضغط */
}

.go {
  height: 70px;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 35px;
  border: 2px solid white; /* إضافة حواف بيضاء */
  padding: 17px;
  background: linear-gradient(45deg, #ff6f61, #f4942b);
  color: white;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* ظل للنص */
  margin: 20px auto; /* تقليل الهامش */
  position: relative; /* إعداد موضع النسبي */
  z-index: 1; /* التأكد من أن الزر في المقدمة */
}

.go:hover {
  background: linear-gradient(45deg, #f4942b, #ff6f61);
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5); /* زيادة الظل عند التحويم */
}

.go:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.go:focus {
  outline: none; /* إزالة الإطار الافتراضي عند التركيز */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* ظل خفيف عند التركيز */
}
.go p{
  margin: auto;
}
.three {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 30px;
  margin-left: 3%;
  flex-wrap: wrap;
}

.three .conti {
  margin: 20px;
}

.alod {
  width: 100%;
}

.slide {
  width: 50% !important;
  height: auto;
  height: 200px !important;
}

.responsive-image {
  width: 40%;
  height: auto;
}

@media (max-width: 768px) {
  .responsive-image {
    width: 90%;
  }
}

/* أنيميشن للأسهم */
@keyframes fadeInArrow {
  from {
    opacity: 0;
    transform: translateY(10px); /* تحرك لأعلى أثناء الظهور */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* العودة لموقعها الطبيعي */
  }
}

.custom-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* العرض */
  height: 40px; /* الارتفاع */
  border-radius: 50%; /* شكل دائري */
  background-color: white; /* لون الخلفية */
  color: #0d5aa4; /* لون السهم */
  font-size: 18px; /* حجم السهم */
  cursor: pointer; /* شكل المؤشر */
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* إضافة ظل */
  opacity: 0;
  animation: fadeInArrow 0.5s forwards ease-in-out; /* إضافة أنيميشن للظهور */
}

.custom-arrow:hover {
  background-color: lightgray; /* تغيير اللون عند التمرير */
  transform: scale(1.1); /* تكبير الحجم عند التمرير */
}

.next-arrow {
  position: absolute;
  top: 50%;
  right: -35px; /* زيادة المسافة من اليمين */
  transform: translateY(-50%);
}

.prev-arrow {
  position: absolute;
  top: 50%;
  left: -35px; /* زيادة المسافة من اليسار */
  transform: translateY(-50%);
}

/* التأثير للكارد في المنتصف */
