.Listofcourse {
  min-height: 100vh;
}
.course-list {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px !important;
}
.vh {
  background: aliceblue;
}
.contii {
  min-width: 350px;
  width: 20% !important;
  height: 450px;
  background-color: #e7e7e7;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 40px;
  gap: 20px;
  color: #0d5aa4;
  justify-content: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

.contii:hover{
  transition: transform 1s ease;
  transform: translateY(-5px) scale(1.1); /* رفع الزر للأعلى وتكبيره عند التمرير */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5); 
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px !important;
  height: 50%;
  text-align: center;
  max-width: 250px !important;
}
.dowm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  height: 50%;
  text-align: center;
  max-width: 250px !important;
}

.up {
  height: 50%;
  width: 100%;
}
.up img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.butt{
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* لضمان وجود مسافة بين الأزرار */
  gap: 50px; /* تحديد المسافة بين الأزرار */
  width: 100%;
  margin-top: 20px;
  margin: 0;
}
.buten1,
.buten2 {
  min-width: 10%;
  height: 45px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  margin: 0;
  background: linear-gradient(#5d9cd6, #0d5aa4);
  color: white;
}
.three {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 30px;
  margin-left: 3%;
  flex-wrap: wrap;
}
.three .conti {
  margin: 20px;
}
.go {
  height: 70px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  border-radius: 25px;
  border: none;
  padding: 17px;
  background: #f4942b;
  color: white;
}
.alod {
  width: 100%;
}
.slide {
  width: 50% !important;
  height: auto;
  height: 200px !important;
}
@media (max-width: 700px){
  .contii{
    width: 80% !important;
    min-width: auto !important;
  }
}
/* تأثير للكارد في المنتصف */
.slick-center .conti {
  transform: scale(1.5); /* تكبير الكارد في المنتصف */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* إضافة ظل */
}
