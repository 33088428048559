@media (max-width: 600px) {
  .ali {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%; gap: 20px;
    margin: auto;
  }
  .CARD {
    margin-left: 15% !important;
    width: 70% !important;
  }
}
