
.page-container {
  height: 85.8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* حاوية الفيديو الرئيسية */
.video-container {
  display: flex;
  width: 70%;
  max-width: 1100px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* ظل خفيف */
  border-radius: 8px;
  background-color: #e2e2e2; /* لون أبيض ناعم للخلفية */
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
}

.video-container:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* ظل أقوى عند التمرير */
}

/* مشغل الفيديو */
.video-main {
  flex: 3;
  padding: 15px 20px; /* Padding أكثر نعومة */
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  border-radius: 8px;
  overflow: hidden;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* معلومات الفيديو */
.video-info {
  margin-top: 20px;
}

.video-title {
  font-size: 20px;
  color: #f59022;
  margin-bottom: 12px;
  font-weight: 800; /* خط أقل كثافة */
}

.channel-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.channel-avatar {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin-right: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08); /* ظل خفيف على الصورة */
}

.channel-title {
  font-size: 16px;
  color: #666;
  font-weight: 400;
}

.video-description {
  color: #0d5aa4;
  line-height: 1.5;
  font-size: 25px;
}

.show-more {
  display: inline-block;
  margin-top: 8px;
  color: #0073e6;
  text-decoration: none;
  font-weight: 400;
}

.show-more:hover {
  text-decoration: underline;
}

.video-list {
  flex: 2;
  padding: 20px;
  background-color: #cacaca;
  border-radius: 12px;
  height: auto;
  overflow-y: auto;
  margin: 20px;
}

.video-list h2 {
  font-size: 20px;
  color: #333333;
  margin-bottom: 16px;
  font-weight: 600;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 8px;
}

.video-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.video-list li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.video-list li:hover {
  background-color: #ffc17e;
  transform: translateY(-2px);
}
.video-list h2:hover{
  color: white;
}

.video-icon {
  color: #1976d2;
  margin-right: 12px;
  font-size: 28px;
}

.lock-icon {
  color: #d32f2f;
  margin-left: auto;
  font-size: 22px;
}

.video-details span {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
}

