@media screen and (max-width: 1000px) {
  .backg {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    padding: 30px;
  }
  .rieghtCon {
    margin: 0px !important;
    width: 100%;
  }
  .rieghtCon h1 {
    margin: 0 !important;
  }
  .leftCon {
    margin: auto !important;
    width: 100%;
    height: auto;
    margin-top: 40px !important;
    flex-direction: row;
  }
  .leftCon img {
    width: 30% !important;
  }
  .mid img {
    display: none;
    height: 70px;
    width: 50px !important;
  }
  .mid {
    width: 20%;
  }
  .mid h2 {
    margin: 0px !important;
  }
  .Button {
    flex-direction: column;
    gap: 20px;
  }
  .Button img {
    width: 80% !important;
  }
}
@media screen and ( max-width: 500px ) {
  .leftCon{
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 10px;
    align-self: flex-start;
  }
  .leftCon img{
    width: 100% !important;
    margin: 0 !important;
  }
  .rieghtCon{
    padding: 0 !important;
    margin-top: 20px !important;
  }
  .mid{
    width: 100% !important;
    
  }
  .head{
    flex-direction: column !important;
    gap : 20px !important;
  }
  .head h1{
    width: 100%;
  }
  .head button{
    width: 100%;
  }
  .time{
   width: 100%;
   margin: 0 !important;
   padding: 0px !important;
  }
  .fuck{
   
  }
  .backg{
    height: auto;
  }
}
