.sponser{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.item{
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}