@media (max-width: 1000px) {
  .nav {
    gap: 0px !important;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: auto !important;
  }
  .section1 {
    margin: 20px;
  }
  .section2 {
    margin: 20px;
  }
  nav img {
    display: none;
    z-index: -1;
  }
  nav ul {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: left;
    padding: 10px;
    gap: 5px;
  }
}
