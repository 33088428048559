@media (max-width: 750px) {
    .container1{
        display: flex;
        flex-direction: column;
        height: auto;
        width: 83%;
        margin: 50 auto;
        
        gap: 30px;
    }

    .item{
        flex-direction: column;
        margin: 0 !important;
    }
    .item img{
        height: 100px;
    }
}
