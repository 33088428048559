@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
* {
  margin: auto;
  font-family: "Outfit", "Cairo", sans-serif;
  box-sizing: border-box;
}
button {
  cursor: pointer;
}
.ap {
  /* background: linear-gradient(#5d9cd6, #0d5aa4); */
  /* background-color: aliceblue; */
  color: #e6e0da !important;
}
.ap h1{
  color: #004d96 !important;
}
.back {
  width: 100%;
  height:100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  /* background-image: url("../images//backg.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: overlay;

}
#background-video {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 111%;
  object-fit: cover;
  z-index: -3;
  background-color: rgba(236, 236, 236, 0.1);
}
.container {
  /* margin-top: 100px !important; */
  width: 60%;
  /* margin: 80px; */
  height: auto;
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 50px;
  padding: 30px;
  margin: auto;
  margin-bottom: 150px;
}
.left {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center !important;
  width: 80% !important;
  text-align: center;
  height: 100%;
}
/* From Uiverse.io by barisdogansutcu */ 
.alkhal {
  padding: 17px 40px;
  border-radius: 50px;
  cursor: pointer;
  border: 0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.5s ease;
  margin-top: 35px;
}

.alkhal:hover {
  letter-spacing: 3px;
  background-color: hsl(31, 100%, 54%);
  color: hsl(0, 0%, 100%);
  box-shadow: hsl(31, 100%, 54%) 0px 7px 29px 0px;
}

.alkhal:active {
  letter-spacing: 3px;
  background-color: hsl(206, 87%, 34%);
  color: hsl(0, 0%, 100%);
  box-shadow: hsl(206, 100%, 32%) 0px 0px 0px 0px;
  transform: translateY(10px);
  transition: 100ms;
}

.rieght {
  width: 50% !important;
  height: auto;
  margin-top: auto;
}
.left h1 {
  font-size: 42px;
  margin: auto;
  color: white !important;
  margin-bottom:35px ;
}
.left p {
  margin-left: 32px;
  margin-top: 0 !important;
  margin: auto !important;
  font-size: 25px;
  color: #ffffff !important;
  text-align: start;
  align-items: center;
}
.left {
  font-size: 20px;
  height: auto;
}
/* From Uiverse.io by adamgiebl */
.cssbuttons-io-button {
  background: linear-gradient(#5d9cd6, #0d5aa4);
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em linear-gradient(#5d9cd6, #0d5aa4);
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
  align-self: flex-start !important;
}

.cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}
