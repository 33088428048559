@media screen and (max-width: 1200px){
  .links{
    display: none;
  }
  .image{
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 700px) {
  .image {
    display: none;
  }
  .links {
    display: none;
  }
  footer {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
    height: 80px;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    margin: auto;
    margin-top: 20px;
  }
  .social {
    width:100%;
    /* margin: 22%; */
    justify-content: center;
  }
  .social img {
    margin: auto 5px; /* تعديل المسافة الأفقية بين الصور */
  }
}
