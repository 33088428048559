@media (max-width: 700px){
    .page-container{
       width: 100%; 
       margin: 0px !important;
       height: 100vh;
    }
    .video-container{
        width: 100%;
        height:100%;
        flex-direction: column;
        background: none;
        box-shadow: none;
        margin: 0 !important;
    }
    .video-main{
        width: 100%;
        height: 0px !important;
        margin-top: 40px;
    }
    .video-list{
        height: 100% !important;
        margin-top: -400px;
    }
}
