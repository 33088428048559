@keyframes blob-bounce {
    0% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
    25% {
        transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }
    50% {
        transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }
    75% {
        transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }
    100% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
}

.container1 {
    width: 90%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 20px;
    height: auto;
    min-height: 200px;
    background: #e7e7e7;
    border-radius: 20px;
    margin: 30px auto;
    justify-content: center;
    color: #0d5aa4 !important;
    margin: 50px auto;
    padding: 10px !important;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
}

.blob {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #ff0000;
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 5s infinite ease;
}
.hh{
    /* text-align: left; */
    font-weight: bold;
    margin-left: 4%;
    margin-right: 4%;
    font-size: 45px;
    margin-top: 40px;
    color: white;
}
.item{
    display: flex;
    flex-direction: row ;
    gap:15px;
    color:  #0d5aa4 !important ;
}

.cont{
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
}