.con{
    text-align: center;
    margin: auto;
    width: 100%;
    height: auto;
    background-color: aliceblue;
    color: #0d5aa4 !important;
}
.contanir{
    width: 85%;
    margin: auto;
    height: 500px;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
}
.text{
    width: 50%;
    margin-left: 2%;
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.Map {
    margin-top: 50px;
    height: 90% !important;
    width: 60%;  
}
.Map Map iframe{
    width: 100%;
    height: 90%;
}
.Map Map{
    width: 100% !important;
    height: 90%;
}
.child{
    margin: 6px 20px;
    display: flex;
    flex-direction: row; 
    justify-content:start !important;
    gap: 20px;
    text-align: start !important;
}
.child1{
    text-align: start !important;
}
.child1 a{
    text-decoration: none;
    color: black;
}
@media (max-width: 600px) {
    .con{
        margin: 0 !important;
        padding: 0;
        height: 100% !important;
    }
    h1{
        margin-left:20px !important;
    }
    .contanir{
        flex-direction: column;
        height:85%;
        background:none;
        gap: 50px;
        margin: auto;
        width: 100%;
        margin-bottom: 110px;
    }
    .text{
        width: 100%;
        margin: 0%;
    }
    .child{
        margin-left: 25px;
    }
    .Map{
        width: 100% !important;
        height: 100% !important;
        margin: 0%;
    }
    .Map Map{
        width: 100% !important;
        height: 100% !important;
    }
    .Map Map iframe{
        width: 100% !important;
        height: 100% !important;
    }
    .photo img{
        border-radius: 25px 25px 0 0 !important;
    }
}