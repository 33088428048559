@media (max-width: 1000px) {
  .ap {
    width: 100vw;
    overflow-x: hidden;
  }
  .back {
    margin: 0 !important;
    width: 100vw;
    height: auto;
  }
  .alkhal{
    background-color: #034a8d !important;
    color: #ffffff !important;
  }
  .container {
    width: 100%;
    background: none;
    margin-top: 5px !important;
    display: block;
    padding: 0 !important;
    /* margin-left: !important; */
    margin-bottom: 0 !important;
    height: auto;
    margin: 20px !important;
  }
  .container img {
    display: none;
  }
  .container h1
  {
    width: 100%;
    color: #034a8d !important;
  } 
  .container p{
    margin: 0 !important;
    color: #034a8d !important;
  }
  .left {
    margin-top: 30px !important;
    margin-bottom: -10px !important;
    width: 100% !important;
    margin: 0 !important;
    
  }
  .left h1 {
    font-size: 55px !important;
    text-align: start !important;
    margin-left: 0 !important;
    width: 100% !important;
  }
  #background-video{
    display: none;
  }
}
