footer {
  /* background-color: #e7e7e7; */
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  gap: 15%;
  margin-top: 38px;
  justify-content: space-between;
  padding: 0;
  margin-bottom: -10px;
  position: relative;
  top: 100%;
  bottom: 0;
  background-image: url('../images/image.png');
  background-size: cover;
  background-position: center;
}
.image {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 120px;
  gap: 0px;
}
.social {
  display: flex;
  flex-direction: row;
  width: 30%;
  height: 120px;
  gap: 0px;
}
.links {
  display: flex;
  flex-direction: row;
  width: 30%;
  height: 120px;
  gap: 10px;
  text-decoration: none;
  color : rgba(252, 134, 0, 0)
}
Link {
  text-decoration: none;
}
footer .links h3{
  text-decoration: none;
}
.social a {
  margin: auto 7px; /* تعديل المسافة الأفقية بين الروابط */
}

/* أو يمكن استخدام هذا الكود لتعديل المسافة بين الصور */
.social img {
  margin: auto 7px; /* تعديل المسافة الأفقية بين الصور */
}
