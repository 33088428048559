.slider {
  width: 90%;
  padding-top: 30px;
  margin-bottom: 40px;
}
.all {
  margin-top: 40px;
}
.Add {
  width: 70%;
  margin: auto;
  height: 600px;
  border: none;
  background-color: aliceblue;
  border-radius: 20px;
  padding: auto;
}
.all {
  text-align: center;
}

.rtl {
  direction: rtl;
  text-align: right; /* يمكنك استخدام left أو right حسب الحاجة */
}

.ltr {
  direction: ltr;
  text-align: left;
}
