.nav {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 200px;
    align-items: center;
    position: sticky;
    height: 75px;
    margin: 0px !important;
    background-color: rgba(0, 0, 0, 0.2);
    /* background-image: url('../images/image.png'); */
    background-size: cover;
    background-position: center;
}
nav ul li{
    display: inline-block;
    padding: 0 20px;
    font-weight: bold;
    position: relative;
}
.Link{
    text-decoration: none;
    color: #0d5aa4;
}
nav li:hover {
    cursor: pointer;
}
nav ul li:hover {
    color: #fb8500;
}

nav ul li::after {
    content: "";
    position: absolute;
    bottom: -5px; 
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fb8500; 
    transform: scaleX(0); 
    transition: transform 0.3s ease; 
}

nav ul li:hover::after {
    transform: scaleX(1);
}
nav ul li.active {
    color: #fb8500 !important; /* اللون البرتقالي */
}

nav ul li.active::after {
    transform: scaleX(0.6);
}

.language-toggle-btn {
    background-color: #0d5aa4;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .language-toggle-btn:hover {
    background-color: #fb8500; /* تغيير لون الزر عند التمرير فوقه */
  }
  
  .language-toggle-btn:focus {
    outline: none;
  }
  
  .section3 {
    display: flex;
    align-items: center;
  }