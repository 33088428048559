.photo {
  background-color: aliceblue;
  margin-top: -170px !important;
  width: 300px;
  margin-left: -95px !important;
  height: 70px;
  border-radius: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.All {

  width: 100%;
  height: 88.3vh;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  gap: 0 !important;
  color: white;
  margin: auto !important;
  background-color: aliceblue;
  color: #0d5aa4;

}
.one {
  color: #f4942b;
  font-size: 50px;
}
.two {
  text-decoration: underline #f4942b;
  font-size: 40px;
}
.jjj{
  margin:  0 !important;
}
.le {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: left;
  /* text-align: left !important; */
  align-items: start !important;
  width: 45%;
  height: auto;
}
.re{
  width: 40%;
  margin: auto;
}
.re .img {
  height: auto;
  width: 100%;
}
.le p {
  font-size: 20px;
  line-height: 1.5;
  margin: 0;
  font-weight: 300;
}
.le h1 {
  font-size: 35px !important;
  /* text-align: left !important; */
  margin-left: 0;
}
